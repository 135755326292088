<template>
  <div v-if="leilao">
    <h5>
      Importação avançada
    </h5>

    <e-row class="m-t">
      <e-col>
        <comitente-select label="Selecione um comitente caso queira filtrar a importação" @loaded="$emit('loadedComitente')" :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="table.filters.comitente" :leilao="leilao" />
      </e-col>
      <e-col class="m-l-md">
        <erp-s-field
            :view="'tl'"
            :label="'Arquivo para importação'"
            :helper-position="'lb'"
        >
          <input type="file" id="input" ref="input">
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 200px">
        <erp-s-field
            :view="'tl'"
            :label="' '"
            :helper-position="'lb'"
        >
          <e-btn @click="uploadAnalise" :loading="loading" label="Analisar" />
          <e-btn @click="importar" color="positive" class="m-l-sm" :disable="!result" :loading="loading" label="Importar" />
        </erp-s-field>

      </e-col>
    </e-row>

    <div class="m-t-lg" v-if="result">
      <div class="divergencias" v-if="result.validator.divergencias">
        {{result.validator.divergencias}} divergências encontradas
        <div class="m-t text-center">
          <u-btn @click="uploadAnalise(true)" label="Exportar erros" :disable="!result" :loading="loading" icon="file-spreadsheet" icon-type="fa" flat size="sm" color="black"/>
        </div>
      </div>

      <div class="block m-t">
        <table class="table-check" cellpadding="0" cellspacing="0">
          <thead>
          <tr>
            <th>Bem</th>
            <th>Status</th>
            <th>Erro</th>
            <th v-for="h in result.validator.headers">{{h}}</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="{'has-errors': item.errors && item.errors.length}" v-for="item in result.validator.items">
            <td>
              <span v-if="item.bem && item.bem.length">{{item.bem[0].descricao}}</span>
              <span v-else>-</span>
            </td>
            <td><span v-if="item.bem && item.bem.length">{{Status.Status[item.bem[0].status].title}}</span><span v-else>-</span></td>
            <td class="error-alert" v-if="item.errors && item.errors.length">{{item.errors.join(', ')}}</td>
            <td v-else>Ok</td>
            <td v-for="(h, k) in result.validator.headers">{{item.originalData[k]}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import {UCheckbox, UTable, UTd, UTh, UTr} from 'uloc-vue'
import {ErpSField, EBtn} from 'uloc-vue-plugin-erp'
import DefaultMixin from '@/components/leiloes/mixin'
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {fileToBase64} from "@/utils/fileToBase64"
import {importarLotes, executarImportacao} from "@/domain/leiloes/services"
import {donwloadFile} from "@/utils/downloadFile"
import * as Status from '@/domain/bem/helpers/Status'

let filters = {
  comitente: null
}

export default {
  name: 'ImportacaoLeilao',
  mixins: [DefaultMixin],
  inject: ['page', 'erplayout'],
  props: {},
  data() {
    return {
      leilao: this.page.leilao,
      table: {
        filters: JSON.parse(JSON.stringify(filters))
      },
      loading: false,
      result: null,
      success: null
    }
  },
  components: {
    ECol,
    ERow,
    ComitenteSelect,
    // ErpCheckbox,
    ErpSField,
    EBtn
    // ErpInput,
    // HelperInputBtn
  },
  mounted() {
    this.page.setActive('ile')
    console.log(this.leilao)
    // this.load()
  },
  computed: {
    Status () {
      return Status
    }
  },
  methods: {
    uploadAnalise (exportar = false) {
      let _exportar = exportar === true
      if (!_exportar) {
        this.result = null
      }
      console.log(this.$refs.input.files)
      if (!this.$refs.input.files || !this.$refs.input.files.length) {
        alert('Selecione um arquivo para analisar e migrar.')
        return
      }

      const file = this.$refs.input.files[0]
      file.filename = file.name
      console.log(file)

      this.loading = true

      fileToBase64(file)
          .then(_file => {
            importarLotes(this.leilao.id,{
              data: _file,
              module: 'lote',
              comitente: this.table.filters.comitente,
              ...file
            }, _exportar)
                .then(response => {
                  this.loading = false
                  this.success = true
                  if (_exportar) {
                    donwloadFile(response)
                    return
                  }
                  console.log(response.data)
                  this.result = response.data
                  // resolve(true)
                })
                .catch(error => {
                  this.loading = false
                  this.alertApiError(error.response)
                  // resolve(false)
                })
          })
    },
    importar () {
      this.loading = true
      this.success = null
      executarImportacao(this.leilao.id, this.result.migration.id)
          .then(response => {
            this.loading = false
            this.success = true
            this.$uloc.dialog({
              title: 'Importação concluída',
              message: `Importação realizada com sucesso.`,
              noCaps: true,
              ok: `Ok`
            })
            this.$router.push({name: 'leiloes.show'})
            // resolve(true)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error.response)
            // resolve(false)
          })
    }
  }
}
</script>

<style scoped lang="stylus">
.divergencias{
  padding 20px
  border red 1px dashed
  color red
  display inline-block
  background-color #FCF6F6
}
  .table-check{
    min-width 100%
    th {
      text-transform uppercase
      text-align left
    }
    th, td{
      padding 4px
      font-size 11spx
    }
    tr.has-errors{
      td{
        background-color #FFA7A7
      }
    }
  }
</style>
